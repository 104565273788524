<!-- 会员服务-服务订单-活动订单 -->

<template>
  <div class="activity-orders main-cnt">
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 280px)" :filters="filters" :groupFilters="groupFilters"
        :needExport="true" :needPrint="true" :apiName="VipApi.getActiveOrder" :columns="tableColumns"
        @detail="openDetailDialog" @projectChange="projectChange" @export="handleExport" @print="handlePrint">
        <template #pay_status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.pay_status, 'pay')">
            {{ row.pay_status_text }}
          </div>
        </template>

        <template #work_status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.work_status, 'work')">
            {{ row.status_text }}</div>
        </template>

        <template #startrRefund="{ row }">
          <template v-if="row.is_init_refund == 1 && authData.indexOf('n_2uoxGZUH8ThiBOlZXetLetfOfB') != -1">
            <el-button class="black-font-btn" @click="openStartrRefundDialog(row)">发起退款</el-button>
          </template>
        </template>

        <template #sureRefund="{ row }">
          <template v-if="row.is_confirm_refund == 1 && authData.indexOf('n_SOIbGtkheowBA4N4S1y7kd7hYu') != -1">
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)">确认退款</el-button>
          </template>
        </template>

        <!-- 取消退款 -->
        <template #cancelRefund="{ row }">
          <template v-if="row.is_cancel_refund == 1 && authData.indexOf('n_ns55cTScV38rPSkgVwMmgY0ka7') != -1">
            <el-popconfirm title="确定要取消该订单退款吗?" @confirm="onConfirmRefund(row)">
              <template #reference>
                <el-button class="theme-font-btn">取消退款</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>
      </common-table>
    </div>
  </div>

  <!-- 详情 -->
  <ActivityDetailDialog ref="activityDetailRef"></ActivityDetailDialog>

  <!-- 发起退款 -->
  <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>

  <!-- 确认退款 -->
  <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>
</template>

<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { BasicApi, VipApi, } from "@/plugins/api.js";
  import ActivityDetailDialog from "../components/ActivityDetailDialog.vue";
  import RefundDialog from "../components/RefundDialog.vue";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  import {
    exportExcel,
    print,
    getPayStatusColors,
    getWorkStatusColors,
    getPayStatusOptions,
    getActiveStatusOptions,
    orderStatusColors,
  } from "@/utils/common.js";
  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "sn",
      label: "订单号",
      minWidth: 250,
    },
    {
      prop: "ma_name",
      label: "活动名称",
      minWidth: 140,
    },
    {
      prop: "mat_first_name",
      prop2: "mat_second_name",
      type: "connect",
      label: "所属模块",
      connectText: "-",
      minWidth: 200,
    },
    {
      prop: "stime",
      prop2: "etime",
      type: "connect",
      label: "活动有效期",
      minWidth: 180,
      connectText: "至",
    },
    {
      prop: "buy_num",
      label: "购买数量",
      minWidth: 80,
    },
    {
      prop: "pay_money",
      label: "支付金额",
      minWidth: 80,
    },
    {
      prop: "refund_money",
      label: "退款金额",
      minWidth: 80,
    },
    {
      prop: "payway_name",
      label: "支付方式",
      minWidth: 80,
    },
    {
      prop: "pay_status",
      label: "支付状态",
      type: "customRender",
      minWidth: 100,
    },
    {
      prop: "work_status",
      label: "业务状态",
      type: "customRender",
      minWidth: 100,
    },
    {
      prop: "pay_time",
      label: "支付时间",
      minWidth: 150,
    },
    {
      prop: "source_text",
      label: "来源渠道",
      minWidth: 120,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 300,
      bottons: [
        {
          name: "发起退款",
          action: "startrRefund",
          type: "customRender",
        },
        {
          name: "确认退款",
          action: "sureRefund",
          type: "customRender",
        },
        {
          name: "取消退款",
          action: "cancelRefund",
          type: "customRender",
        },
        {
          name: "详情",
          action: "detail",
          token: "n_NGKJdsNycK13oLCWaUT0xkHKKz",
        },
      ],
    },
  ]);

  const groupFilters = ref([
    {
      filterType: "select",
      name: "source",
      value: "",
      label: "购买渠道",
      options: [],
      val: "id",
      lab: "name",
    },
    {
      filterType: "select",
      name: "pay_status",
      value: 2,
      label: "支付状态",
      options: [],
      val: "value",
      lab: "label",
      otherOptionsName: "work_status",
      showOtherOptionsValue: 2,
    },
    {
      filterType: "multipleSelect",
      name: "work_status",
      value: [],
      label: "业务状态",
      options: [],
      val: "value",
      lab: "label",
    },
    {
      filterType: "partDaterange",
      name1: "stime",
      value1: "",
      name2: "etime",
      value2: "",
      label: "支付时间",
    },
  ]);
  const filters = ref([
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "搜索订单号、活动名称、会员姓名、手机号",
    },
    {
      filterType: "select",
      name: "p_id",
      value: "",
      placeholder: "请选择项目",
      options: [],
      lab: "p_name",
      val: "p_id",
      action: "projectChange",
    },
    {
      filterType: "select",
      name: "ma_id",
      value: "",
      placeholder: "请选择活动",
      options: [],
      lab: "ma_name",
      val: "ma_id",
    },
  ]);
  const tableRef = ref(null);  // 列表表格对象
  /**
   *
   * 获取项目数据
   * 
   **/
  const getProjectData = () => {
    BasicApi.projectSelect().then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   *
   * 选择项目
   * 
   **/
  const projectChange = (obj) => {
    filters.value[2].value = "";
    filters.value[2].options = [];
    tableRef.value.tableLoad();
    if (obj.value) {
      BasicApi.getAllActiveData({ p_id: obj.value, }).then((res) => {
        if (res.Code === 200) {
          filters.value[2].options = res.Data ? res.Data : [];
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
  };
  const payStatusOptions = ref(getPayStatusOptions());
  const payStatusColors = ref(getPayStatusColors());
  const workStatusOptions = ref(getActiveStatusOptions());
  const workStatusColors = ref(getWorkStatusColors());
  const payStatusLabelArr = ref([]);
  /**
   *
   * 组合-支付状态
   * 
   **/
  const getPayStatusLabelArr = () => {
    for (var key in payStatusOptions.value) {
      payStatusOptions.value[key] &&
        payStatusLabelArr.value.push({
          label: payStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[1].options = payStatusLabelArr.value;
  }
  /**
   *
   * 组合-业务状态
   * 
   **/
  const workStatusLabelArr = ref([]);
  const getWorkStatusLabelArr = () => {
    for (var key in workStatusOptions.value) {
      workStatusOptions.value[key] &&
        workStatusLabelArr.value.push({
          label: workStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[2].options = workStatusLabelArr.value;
  }
  /**
   *
   * 组合-购买渠道
   * 
   **/
  const getSourceOptions = () => {
    BasicApi.orderSourceOptions({}).then((res) => {
      if (res.Code === 200) {
        groupFilters.value[0].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  onMounted(() => {
    getProjectData();
    getPayStatusLabelArr();
    getWorkStatusLabelArr();
    getSourceOptions();
    tableRef.value.tableLoad();
  });

  const activityDetailRef = ref(null);
  /**
  *
  * 详情按钮
  * 
  **/
  const openDetailDialog = (row) => {
    activityDetailRef.value.openDialog(row.sn);
  }

  const initRefundRef = ref(null);  // 发起退款对象
  const currentRefundSn = ref("");  // 当前行sn
  const sureRefundRef = ref(null);  // 确认退款对象
  /**
   *
   * 发起退款
   * 
   **/
  const openStartrRefundDialog = (row) => {
    currentRefundSn.value = row.sn;
    initRefundRef.value.openDialog("发起退款", row.pay_money);
  };
  /**
  *
  * 发起退款确定按钮
  * 
  **/
  const handleInitRefund = (data) => {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    VipApi.initiateRefundOrder(parmas).then((res) => {
      initRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        initRefundRef.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   *
   * 确认退款
   * 
   **/
  const openSureRefundDialog = (row) => {
    currentRefundSn.value = row.sn;
    sureRefundRef.value.openDialog(
      "确认退款",
      row.pay_money,
      row.confirm_refund_money
    );
  }
  /**
   *
   * 确认退款确定按钮
   * 
   **/
  const handleSureRefund = (data) => {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    VipApi.confirmRefundOrder(parmas).then((res) => {
      sureRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        sureRefundRef.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
   *
   * 导出数据处理
   * 
   **/
  const propertiesConfig = ref([
    { field: "sn", displayName: "订单号" },
    { field: "ma_name", displayName: "活动名称" },
    { field: "moduleTitle", displayName: "所属模块" },
    { field: "validity", displayName: "活动有效期" },
    { field: "buy_num", displayName: "购买数量" },
    { field: "pay_money", displayName: "支付金额" },
    { field: "refund_money", displayName: "退款金额" },
    { field: "payway_name", displayName: "支付方式" },
    { field: "pay_status_text", displayName: "支付状态" },
    { field: "status_text", displayName: "业务状态" },
    { field: "pay_time", displayName: "支付时间" },
    { field: "source_text", displayName: "购买渠道" },
  ]);
  function processData(data) {
    data.length &&
      data.forEach((el) => {
        el.moduleTitle = el.mat_first_name + "-" + el.mat_second_name;
        el.validity =
          el.stime === el.etime ? el.stime + "当天" : el.stime + "至" + el.etime;
      });
    return data;
  }
  /**
   *
   * 打印
   * 
   **/
  const handlePrint = (data) => {
    print("活动订单", processData(data), propertiesConfig.value);
  }
  /**
   *
   * 导出
   * 
   **/
  const handleExport = (data) => {
    try {
      exportExcel("活动订单", processData(data), propertiesConfig.value);
      ElMessage.success({
        message: "导出成功！",
      });
    } catch (e) {
      ElMessage.error({
        message: "导出失败！",
      });
    }
  }
  /**
   * 
   * 取消退款确定按钮
   * 
   * **/
  const onConfirmRefund = (row) => {
    VipApi.cancelRefundOrder({ sn: row.sn }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("取消退款成功！");
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
</script>
<style lang="scss">
  .activity-orders {
    font-family: "Source Han Sans CN";

    .search-input {
      width: 380px !important;
    }

    .el-input-group__append {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .content {
      padding: 15px 20px 20px;
    }

    .font-gray {
      color: var(--text-gray-color);
    }

    .font-999 {
      color: var(--text-third-color);
    }

    .refund-money {
      width: 100%;
      padding: 20px 0;
      background-color: var(--theme-bg-color);
      text-align: center;
      border-radius: 10px;
      margin-bottom: 20px;
      color: var(--text-gray-color);

      >div {
        color: var(--text-color);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
</style>