<!-- 会员服务-服务订单-活动订单 -->

<template>
  <div class="activity-order-detail">
    <w-dialog ref="detailDialogRef" title="活动订单详情" width="60%" btnWidth="140px" top="3vh" :hideFooter="true"
      class="order-detail">
      <div class="basic-infor">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">活动名称</div>
              <div class="basic-infor-content">{{ detailInfo.ma_name }}×{{ detailInfo.buy_num }}份</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">所属模块</div>
              <div class="basic-infor-content">
                <span>{{ detailInfo.mat_first_name }}</span>
                <span v-if="detailInfo.mat_second_name">-{{ detailInfo.mat_second_name }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">所属项目</div>
              <div class="basic-infor-content">{{ detailInfo.p_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">订单号码</div>
              <div class="basic-infor-content">{{ detailInfo.sn }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">购买渠道</div>
              <div class="basic-infor-content">{{ detailInfo.source_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付方式</div>
              <div class="basic-infor-content">{{ detailInfo.payway_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">下单时间</div>
              <div class="basic-infor-content">{{ detailInfo.ctime }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付金额</div>
              <div class="basic-infor-content">{{ detailInfo.pay_money }}元</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">退款金额</div>
              <div class="basic-infor-content">{{ detailInfo.refund_money }}元</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付时间</div>
              <div class="basic-infor-content">{{ detailInfo.pay_time }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付状态</div>
              <div class="status-text" :class="orderStatusColors(detailInfo.pay_status, 'pay')">
                {{ detailInfo.pay_status_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">订单状态</div>
              <div class="status-text" :class="orderStatusColors(detailInfo.work_status, 'work')">
                {{ detailInfo.status_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">活动日期</div>
              <div class="basic-infor-content">{{ detailInfo.stime }}至{{ detailInfo.etime }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">下单会员</div>
              <div class="basic-infor-content">{{ detailInfo.m_realname }}({{ detailInfo.m_mobile }})</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <w-title :title_name="'子订单信息'"></w-title>
      <common-table ref="peopleTableRef" :ispaging="false" :tableData="tableData" :columns="peopleTableColumns"
        tableHeight="200">
        <template #status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.sub_work_status, 'work')">
            {{ row.sub_work_status_text }}
          </div>
        </template>
      </common-table>

      <w-title :title_name="'退款明细'"></w-title>
      <common-table ref="refundTableRef" :ispaging="false" :tableData="refundData" :columns="refundTableColumns">
      </common-table>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, computed, watch, nextTick, } from "vue";
  import { VipApi, } from "@/plugins/api.js";
  import { ElMessage, } from "element-plus";
  import { useStore, } from "vuex";
  import { orderStatusColors, } from "@/utils/common.js";

  export default {
    emits: ["submit"],
    components: {},
    setup() {
      const detailDialogRef = ref(null);
      const detailInfo = ref({});
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      const refundData = ref([]);
      const authInfo = ref({});
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const tableData = ref([]);
      const peopleTableColumns = ref([
        {
          prop: "sn",
          label: "子订单号",
          minWidth: 220,
        },
        {
          prop: "sub_name",
          label: "商品名称",
        },
        {
          prop: "chlid_type",
          label: "订单类别",
        },
        {
          prop: "sub_num",
          label: "购买数量",
        },
        {
          prop: "sub_money",
          label: "原价金额",
        },
        {
          prop: "sub_sdate",
          label: "使用期限",
        },
        {
          prop: "status",
          label: "业务状态",
          type: "customRender",
          minWidth: 100,
        },
      ]);

      const getDetail = (type) => {
        VipApi.getActiveOrderDetails({ sn: snId.value }).then((res) => {
          if (res.Code === 200) {
            detailInfo.value = res.Data;
            tableData.value = res.Data.childOrder;
            refundData.value = res.Data.refund;
            detailDialogRef.value.show();
            nextTick(() => {
              peopleTableRef.value.tableLoad();
              refundTableRef.value.tableLoad();
            });
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      const snId = ref(null);
      const peopleTableRef = ref(null);
      const refundTableRef = ref(null);
      const refundTableColumns = ref([
        {
          prop: "refund_sn",
          label: "退款单号",
          minWidth: 140,
        },
        {
          prop: "refund_money",
          label: "退款金额",
        },
        {
          prop: "refund_payway_name",
          label: "退款方式",
        },
        {
          prop: "refund_confirm_user",
          label: "退款确认人",
        },
        {
          prop: "refund_confirm_time",
          label: "退款时间",
        },
        {
          prop: "refund_reason",
          label: "退款原因",
        },
      ]);

      const openDialog = (id) => {
        snId.value = id;
        getDetail();
      }
      return {
        detailDialogRef,
        openDialog,
        detailInfo,
        authInfo,
        authData,
        peopleTableRef,
        tableData,
        peopleTableColumns,
        refundData,
        refundTableRef,
        refundTableColumns,
        orderStatusColors,
      };
    },
  };
</script>

<style lang="scss">
  .activity-order-detail {
    .basic-infor {
      background-color: #F3F4F8;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
    }

    .basic-infor-title {
      color: #92979E;
      width: 80px;
      text-align: left;
      margin-bottom: 10px;
    }

    .basic-infor-content {
      color: #262C30;
    }
  }
</style>